import Vue from 'vue'
import VueRouter from 'vue-router'
//首页
import Home from '../pages/home/index.vue'/* 首页 */
import Mine from '../pages/mine/index.vue'/* 我的 */
import Choose from '../pages/choose/index.vue'/* 选妃 */
import List from '../pages/choose/list.vue'/* 选妃列表 */
import Profile from '../pages/choose/profile.vue'/* 选妃详情 */
import Video from '../pages/video/index.vue'/* 视频 */
import Game from '../pages/game/index.vue'/* 游戏 */
import Login from '../pages/login/index.vue'/* 登录 */
import Register from '../pages/login/register.vue'/* 注册 */
import ServiceOnline from '../pages/mine/ServiceOnline.vue'/* 客服列表 */
import ServicePage from '../pages/mine/ServicePage.vue'/* 客服详情界面 */
import Setting from '../pages/mine/Setting.vue'/* 设置 */
import Infomation from '../pages/mine/Infomation.vue'/* 基本信息 */
import Setname from '../pages/mine/Setname.vue'/* 修改姓名 */
import Language from '../pages/mine/Language.vue'/* 语言选择 */
import Setsex from '../pages/mine/Setsex.vue'/* 修改姓名 */
import Recharge from '../pages/mine/Recharge.vue'/* 充值 */
import SetPayPassword from '../pages/mine/SetPayPassword.vue'/* 修改提现密码 */
import EditPayPassword from '../pages/mine/EditPayPassword.vue'/* 修改提现密码 */

import SetLoginPassword from '../pages/mine/SetLoginPassword.vue'/* 修改提现密码 */
import Lottery from '../pages/lottery/index.vue'/* 彩票详情 */
import Notice from '../pages/mine/Notice.vue'/* 公告 */
import PlayVideo from '../pages/video/PlayVideo'/* 视频播放页面 */
import Setbank from '../pages/mine/Setbank'/* 视频播放页面 */
import BindCard from '../pages/mine/BindCard'/* 绑定银行卡界面 */
import Withdraw from '../pages/mine/Withdraw'/* 绑定银行卡界面 */
import Personalreport from '../pages/mine/Personalreport'/* 个人表报 */
import GameRecord from '../pages/mine/GameRecord'/* 游戏记录 */
import WithdrawRecord from '../pages/mine/WithdrawRecord'/* 提现记录 */
import RechargeRecord from '../pages/mine/RechargeRecord'/* 充值记录 */
import Moneylog from '../pages/mine/Moneylog'/* 资金明细 */
import Channel from '../pages/mine/Channel'/* 银行卡信息 */
import nHomeList from '../pages/nHome/list'/* 银行卡信息 */
import nHomeDetail from '../pages/nHome/detail'/* 银行卡信息 */
import luckDraw from '../pages/luckDraw/index'/* 抽奖页 */
import xuanfei from '../pages/xuanfei/index'/* 抽奖页 */




import au from '../pages/authentication/index'

// import auhome from '../pages/authentication/page/home'
import aun_home from '../pages/authentication/page/n_home'
import auorder from '../pages/authentication/page/order'
import auorderlist from '../pages/authentication/page/orderlist'

import auinvitation from '../pages/authentication/page/invitation'

import aurecharge from '../pages/authentication/page/recharge'

import auwithdraw from '../pages/authentication/page/withdraw'
import auteam from '../pages/authentication/page/team'
import auMine from '../pages/authentication/page/mine'




Vue.use(VueRouter)
const routes = [
  { path: '/', redirect: '/Home', component: Home, meta: { title: '' } },
  { path: '/Home', name: 'home', component: Home, meta: { title: '' } },
  { path: '/nHomeList', name: 'nHomeList', component: nHomeList, meta: { title: '' } },
  { path: '/nHomeDetail', name: 'nHomeDetail', component: nHomeDetail, meta: { title: '' } },
  { path: '/Choose', name: 'choose', component: Choose, meta: { title: '' } },
  { path: '/List', name: 'list', component: List, meta: { title: '' } },
  { path: '/Profile', name: 'profile', component: Profile, meta: { title: '' } },
  { path: '/Mine', name: 'mine', component: Mine, meta: { title: '' } },
  { path: '/Video', name: 'video', component: Video, meta: { title: '' } },
  { path: '/Game', name: 'game', component: Game, meta: { title: '' } },
  { path: '/Login', name: 'login', component: Login, meta: { title: '' } },
  { path: '/Register', name: 'register', component: Register, meta: { title: '' } },
  { path: '/ServiceOnline', name: 'ServiceOnline', component: ServiceOnline, meta: { title: '' } },
  { path: '/ServicePage', name: 'ServicePage', component: ServicePage, meta: { title: '' } },
  { path: '/Setting', name: 'Setting', component: Setting, meta: { title: '' } },
  { path: '/Infomation', name: 'Infomation', component: Infomation, meta: { title: '' } },
  { path: '/Setname', name: 'Setname', component: Setname, meta: { title: '' } },
  { path: '/Setsex', name: 'Setsex', component: Setsex, meta: { title: '' } },
  { path: '/Language', name: 'Language', component: Language, meta: { title: '' } },
  { path: '/Recharge', name: 'Recharge', component: Recharge, meta: { title: '' } },
  { path: '/SetPayPassword', name: 'SetPayPassword', component: SetPayPassword, meta: { title: '' } },
  { path: '/EditPayPassword', name: 'EditPayPassword', component: EditPayPassword, meta: { title: '' } },

  { path: '/SetLoginPassword', name: 'SetLoginPassword', component: SetLoginPassword, meta: { title: '' } },
  { path: '/Lottery', name: 'Lottery', component: Lottery, meta: { title: '' } },
  { path: '/Notice', name: 'Notice', component: Notice, meta: { title: '' } },
  { path: '/PlayVideo', name: 'PlayVideo', component: PlayVideo, meta: { title: '' } },
  { path: '/Setbank', name: 'Setbank', component: Setbank, meta: { title: '' } },
  { path: '/BindCard', name: 'BindCard', component: BindCard, meta: { title: '' } },
  { path: '/Withdraw', name: 'Withdraw', component: Withdraw, meta: { title: '' } },
  { path: '/Personalreport', name: 'Personalreport', component: Personalreport, meta: { title: '' } },
  { path: '/WithdrawRecord', name: 'WithdrawRecord', component: WithdrawRecord, meta: { title: '' } },
  { path: '/GameRecord', name: 'GameRecord', component: GameRecord, meta: { title: '' } },
  { path: '/RechargeRecord', name: 'RechargeRecord', component: RechargeRecord, meta: { title: '' } },
  { path: '/Moneylog', name: 'Moneylog', component: Moneylog, meta: { title: '' } },
  { path: '/Channel', name: 'Channel', component: Channel, meta: { title: '' } },
  { path: '/luckDraw', name: 'luckDraw', component: luckDraw, meta: { title: '' } },
  { path: '/xf', name: 'xuanfei', component: xuanfei, meta: { title: '' } },
  



  {
    path: '/au', name: 'au', component: au,
    redirect:'/au/home',
    children: [ 
      {
        path: 'home', 
        name: 'auhome',
        component: aun_home
      },

      {
        path: 'order', 
        name: 'auorder',
        component: auorder
      },
      {
        path: 'orderlist', 
        name: 'auorderlist',
        component: auorderlist
      },

      
      {
        path: 'invitation', 
        name: 'auinvitation',
        component: auinvitation
      },
      {
        path: 'recharge', 
        name: 'aurecharge',
        component: aurecharge
      },
      {
        path: 'withdraw', 
        name: 'auwithdraw',
        component: auwithdraw
      },
      {
        path: 'team', 
        name: 'auteam',
        component: auteam
      },
      {
        path: 'auMine', 
        name: 'auMine',
        component: auMine
      }
    ],
  },
  
  
  
  
  

];

//生成路由实例
const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {         //修改标题路由配置加上这个
  // document.title = to.name
  if (to.path == from.path) return
  next()
})
//先把VueRouter原型对象的push保存一份
const originPush = VueRouter.prototype.push
//重写push方法
VueRouter.prototype.push = function (location, resolve, reject) {
  //调用保存的push方法
  //但是保存的push方法是挂载在window的方法 所以要通过call修改this的指向
  if (resolve && reject) {
    originPush.call(this, location, resolve, reject);
  } else {
    originPush.call(this, location, () => { }, () => { });
  }
}
export default router