<template>
  <van-tabbar v-if="show" v-model="active" active-color="#7e5678" :border="true" inactive-color="#979799">
    <!-- 首页 -->
    <van-tabbar-item to="/au/home">
      <span class="foorterTxt">{{ $t("foorter.index") }}</span>
      <template #icon="props">
        <img :src="props.active ? '/img/au/ahome.png?d=' + new Date() * 1 : '/img/au/home.png?d=' + new Date() * 1"
          :alt="$t('foorter.index')" v-show="show !== 0" />
        <img :src="props.active ? '/img/au/ahome.png?d=' + new Date() * 1 : '/img/au/home.png?d=' + new Date() * 1"
          :alt="$t('foorter.index')" :class="$t('foorter.index')" style="height: 4rem" v-show="show === 0" />
      </template>
    </van-tabbar-item>
    <!-- 视频 -->
    <van-tabbar-item to="/au/orderlist">
      <span class="foorterTxt">{{ $t("auth.注文") }}</span>
      <template #icon="props">
        <img :src="props.active ? '/img/au/aorder.png?d=' + new Date() * 1 : '/img/au/order.png?d=' + new Date() * 1"
          :alt="$t('auth.注文')" v-show="show !== 3" />
        <img :src="props.active ? '/img/au/aorder.png?d=' + new Date() * 1 : '/img/au/order.png?d=' + new Date() * 1"
          :alt="$t('auth.注文')" :class="$t('foorter.video')" style="height: 4rem" v-show="show === 3" />
      </template>
    </van-tabbar-item>
    <!-- 中间 -->
    <van-tabbar-item to="/au/order">
      <!-- <span class="foorterTxt">{{ $t("concubine.concubine") }}</span> -->
      <template #icon="props">

        <img style="width: 50px;height: 50px;"
          :src="props.active ? '/img/au/grab.png?d=' + new Date() * 1 : '/img/au/grab.png?d=' + new Date() * 1"
          :alt="$t('foorter.subscribe')" />
      </template>
    </van-tabbar-item>
    <!-- 预约 -->
    <van-tabbar-item @click="toServe">
      <span class="foorterTxt">{{ $t("auth.客服") }}</span>
      <template #icon="props">
        <img :src="props.active ? '/img/au/kefu.png?d=' + new Date() * 1 : '/img/au/kefu.png?d=' + new Date() * 1"
          :alt="$t('foorter.客服')" v-show="show !== 1" />
        <img :src="props.active ? '/img/au/kefu.png?d=' + new Date() * 1 : '/img/au/kefu.png?d=' + new Date() * 1"
          :alt="$t('foorter.客服')" :class="$t('foorter.subscribe')" style="height: 4rem" v-show="show === 1" />
      </template>
    </van-tabbar-item>

    <!-- 我的 -->
    <van-tabbar-item to="/au/auMine">
      <span class="foorterTxt">{{ $t("auth.我的") }}</span>
      <template #icon="props">
        <img :src="props.active ? '/img/au/my.png?d=' + new Date() * 1 : '/img/au/my.png?d=' + new Date() * 1"
          :alt="$t('auth.我的')" v-show="show !== 4" />
        <img :src="props.active ? '/img/au/my.png?d=' + new Date() * 1 : '/img/au/my.png?d=' + new Date() * 1"
          :alt="$t('auth.我的')" :class="$t('foorter.my')" style="height: 4rem" v-show="show === 4" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0
    };
  },
  watch: {
    $route(to) {
      console.log(to.name)
      if (to.name == "auhome") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "auorderlist") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "auorder") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "aukefu") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "auMine") {
        this.active = 4;
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "auhome") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "auorderlist") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "auorder") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "aukefu") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "auMine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    toServe() {
      // window.location.href = this.$store.getters.getBaseInfo.kefu
      window.open(this.$store.getters.getBaseInfo.kefu)
      
    }
  }
};
</script>

<style lang="less" scoped>
@tabbar-height: 110px;
@tabbar-img: 50px;

.van-tabbar {
  height: @tabbar-height;
}

.van-tabbar-item__icon img {
  height: @tabbar-img;
}

.van-tabbar-item {
  font-size: 26px;
}

.tui {
  width: 5rem;
  height: 5rem !important;
  margin-top: -7.333vw;
  background-color: white;
  border-radius: 50%;
  border: 10px solid white;
  z-index: 10;
}

[class*="van-hairline"]::after {
  border: none !important;
}

.foorterTxt {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 0.725rem;
}
</style>
