<template>
    <div>


        <van-nav-bar class="nav-bar">
            <template #left>
                <!-- <van-icon name="arrow-left" color="#fff" @click="back()" /> -->
                <van-icon name="wap-home-o" @click="toHome" color="#333" size="36" />
            </template>
            <template #right>
                <img style="width: 36px;" @click="toServe" src="/img/au/kefu.png" />
            </template>
        </van-nav-bar>
        <div class="auhomepage container mb-3" style="padding-bottom: 1px;padding-top: 2vh;">

            <div class="row" style="width: 100%;">
                <div class="col">
                    <div style="font-size: 26px;line-height: 1;" class="mb-0 font-weight-normal"> {{
                        $t("reservation.unit") }}
                        {{
                            userInfo.money }}</div>
                    <p style="opacity: 0.7;font-size: 16px;margin: 6px 0;line-height: 1;color: #666;"
                        class="text-mute text-secondary">{{ $t('xuanfei.zhzj') }}</p>
                </div>
                <div class="col-auto" @click="doPay()"><img style="width: 46px;" src="/img/au/recharge.png" alt="">
                </div>
            </div>

            <!-- <gameComponents style="height: 90px;" ref="HMslotMachine"></gameComponents> -->

            <div class="yongjin">
                <div class="yonngjin_header">

                    <div>{{ $t('xuanfei.yj') }}{{ lottery.commission ? lottery.commission * 100 : 0 }}%</div>
                    <div style="border: 1px solid #fff;margin:0 20px;"></div>
                    <div>{{ lottery.buy_total }}{{ $t('xuanfei.dd') }}</div>
                </div>
                <div class="yongjin_content">
                    <div style="flex: 1;">
                        <div style="font-size: 32px;margin-bottom: 10px;">{{ lottery.finish_total || 0 }}</div>
                        <div>{{ $t('xuanfei.ywc') }}</div>
                    </div>
                    <div style="flex: 1;">
                        <div style="font-size: 32px;margin-bottom: 10px;">{{ lottery.other_total }}</div>
                        <div>{{ $t('xuanfei.qyd') }}</div>
                    </div>
                    <div style="flex: 1;">
                        <div style="font-size: 32px;margin-bottom: 10px;">{{ lottery.unfinished_total || 0 }}</div>
                        <div>{{ $t('xuanfei.wwc') }}</div>
                    </div>
                </div>
            </div>
            <div class="yongjin_num">
                <div class="yongjin_num_header">

                    <div style="flex: 1;text-align: center;">
                        <div style="margin-bottom: 10px;">{{ $t("reservation.unit") }} {{ lottery.all_commission }}</div>
                        <div>{{ $t('xuanfei.hdyj') }}</div>
                    </div>
                    <div style="flex: 1;text-align: center;">
                        <div style="margin-bottom: 10px;">{{ $t("reservation.unit") }} {{ lottery.other_money || 0 }}
                        </div>
                        <div>{{ $t('xuanfei.ce') }}</div>
                    </div>
                    <div style="flex: 1;text-align: center;">
                        <div style="margin-bottom: 10px;">{{ $t("reservation.unit") }} {{ lottery.today_commission }}
                        </div>
                        <div>{{ $t('xuanfei.jryj') }}</div>
                    </div>
                </div>
                <div class="yongjin_num_btn">
                    <div class="btn" @click="getOrder">{{ $t('xuanfei.ksqd') }}</div>

                </div>
                <center>
                    <div class="imsggg" style="height: 70vh;">
                        <img src="/img/111.jpg" alt="" style="width: 100%;">
                        <div style="margin-left: 10px;margin: 10px 0;">
                            <img src="/img/222.jpg" alt=""
                                style="width: 31%;">
                            <img src="/img/333.jpg" alt="" style="width: 31%;margin: 0 2%;">
                            <img src="/img/444.jpg" alt="" style="width: 31%;">
                        </div><img src="/img/555.jpg" alt="" style="width: 54%;">
                        <img src="/img/666.jpg" alt="" style="width: 38%;margin-left: 2%;">
                    </div>
                </center>
            </div>
        </div>


        <van-popup v-model="order_show" v-if="order_show_data" class="order_list_popup" closeable
            :close-on-click-overlay="false">
            <div class="order_list_popup_title">
                {{ $t('xuanfei.status') }}
            </div>
            <div class="group_cell">
                <div class="group_left">{{ $t('xuanfei.order_time') }}</div>
                <div class="group_right">{{ dayjs(order_show_data.create_time * 1000).format("YYYY-MM-DD") }}</div>
            </div>
            <div class="group_cell">
                <div class="group_left">{{ $t('xuanfei.ddh') }}</div>
                <div class="group_right">{{ order_show_data.expect }}</div>
            </div>

            <div style="display: flex;align-items: center;margin-top: 20px;background: #f2ecf3;">
                <img class="order_list_img" :src="order_show_data.goods.pic" alt="">
                <div class="order_list_name">{{ order_show_data.goods.name }}</div>
            </div>
            <div class="group_cell">

                <div class="group_left">{{ $t('xuanfei.hj') }}</div>
                <div class="group_right">{{ order_show_data.money }}</div>
            </div>
            <div class="group_cell">

                <div class="group_left">{{ $t('xuanfei.sl') }}</div>
                <div class="group_right" style="color: #ffb66b;">{{ order_show_data.profit }}</div>
            </div>

            <div style="display: flex;align-items: center;margin-top: 20px;">
                <div class="order_list_popup_btn" @click="order_show = false">{{ $t('xuanfei.qx') }}</div>
                <div class="order_list_popup_btn order_list_popup_btn_active" @click="order_pay">
                    {{ $t('xuanfei.order_btn') }}
                </div>
            </div>
        </van-popup>
    </div>
</template>
<script>

import dayjs from 'dayjs'
export default {
    data() {
        return {
            dayjs,
            userInfo: {
                money: 0
            },
            lottery: {

            },
            order_show: false,
            order_show_data: null,

            prizeList: [
                { name: '1', value: '1', img: '/img/au/order/slot1.jpg' },
                { name: '2', value: '2', img: '/img/au/order/slot2.jpg' },
                { name: '3', value: '3', img: '/img/au/order/slot3.jpg' },
                { name: '4', value: '4', img: '/img/au/order/slot4.jpg' },
                { name: '5', value: '5', img: '/img/au/order/slot5.jpg' },
                { name: '6', value: '6', img: '/img/au/order/slot6.jpg' },
                { name: '7', value: '7', img: '/img/au/order/slot7.jpg' },
                { name: '8', value: '8', img: '/img/au/order/slot8.jpg' },
                { name: '9', value: '9', img: '/img/au/order/slot9.jpg' },
                { name: '10', value: '10', img: '/img/au/order/slot10.jpg' },
                { name: '11', value: '11', img: '/img/au/order/slot11.jpg' },
                { name: '12', value: '12', img: '/img/au/order/slot12.jpg' },
                { name: '13', value: '13', img: '/img/au/order/slot13.jpg' },
                { name: '14', value: '14', img: '/img/au/order/slot14.jpg' },
                { name: '15', value: '15', img: '/img/au/order/slot15.jpg' },
                { name: '16', value: '16', img: '/img/au/order/slot16.jpg' },
                { name: '17', value: '17', img: '/img/au/order/slot17.jpg' },
                { name: '18', value: '18', img: '/img/au/order/slot18.jpg' }
            ]
        }
    },
    created() {
        this.getLotteryInfo()

    },
    mounted() {

        // this.$refs.HMslotMachine.init({
        //     prizeList: this.prizeList,
        //     defaultResults: ['1', '5', '8'],
        //     duration: 4000,
        //     direction: 'up'
        // })
        // setTimeout(() => {
        //     this.start()

        // }, 300)
    },

    methods: {

        start() {
            // roll(options)开始摇奖
            // 参数说明
            // results 开奖结果，结构[value,value,value] value为奖品数据的value值
            // success 开奖回调 e={results} results为开奖结果数据
            // this.$refs.HMslotMachine.roll({
            //     results: this.getResults(),
            //     success: (e) => {

            //         console.log("success e: ", e);
            //     }
            // })
        },
        getResults() {
            // 生成随机的抽奖结果 实际应用应该ajax请求后台，让后台返回开奖结果
            let max = this.prizeList.length - 1;
            let arr = [Math.floor(Math.random() * (max - 1 + 1) + 1), Math.floor(Math.random() * (max - 1 + 1) + 1), Math.floor(Math.random() * (max - 1 + 1) + 1)];
            return [
                this.prizeList[arr[0]].value,
                this.prizeList[arr[1]].value,
                this.prizeList[arr[2]].value
            ]
        },
        order_pay() {

            this.$http({
                method: 'get',
                url: 'doplaceOrder',
                data: {
                    order_id: this.order_show_data.order_id
                }
            }).then(res => {
                if (res.code === 200) {
                    this.order_show = false;
                    this.order_show_data = null
                    this.$toast({
                        message: res.msg,
                        duration: 3000
                    });
                    this.getLotteryInfo()
                } else if (res.code === 401) {
                    this.$toast({
                        message: res.msg,
                        duration: 3000
                    });
                    this.order_show = false;
                    this.order_show_data = null
                }
            })
        },

        getOrder() {
            console.log(this.lottery.now_expect)
            this.$http({
                method: 'post',
                data: {
                    //    item:this.gameitem,
                    //    money:this.money,
                    lid: this.lottery.id,
                    mid: this.userInfo.id,
                    expect: this.lottery.now_expect
                },
                url: 'game_place_order'
            }).then(res => {
                if (res.code === 200) {
                    this.order_show_data = res.data
                    this.order_show = true
                    //   this.$toast(res.msg);

                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
                this.isDisabled = false
            })
        },
        getLotteryInfo() {
            this.$http({
                method: 'get',
                data: { key: 'game1' },
                url: 'lottery_get_info'
            }).then(res => {
                if (res.code === 200) {
                    // if(parseFloat(this.userInfo.money) < parseFloat(res.data.condition)){
                    //   this.$toast(this.$t("reservation.contact_admin"));
                    //   this.$router.push({path:'/Home'})
                    //   return false;
                    // }

                    this.lottery = res.data.lottery_info;
                    console.log(this.lottery, 888)
                    this.userInfo = res.data.info

                    if (this.time / 1000 === 59) {
                        this.$toast(this.$t("reservation.prize_succ") + this.lottery.now_expect);
                    }
                } else if (res.code === 401) {
                    this.$toast({
                        message: res.msg,
                        duration: 3000
                    });
                }
            })

        },
        doPay() {

            if (!localStorage.getItem('token')) {
                this.$router.push({ path: '/Login' })
            }
            if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
                this.$toast.fail(this.$t("setting.doPay"));
            } else {
                this.$router.push({
                    name: 'Recharge'
                })
            }
        },
        toHome() {
            this.$router.push("/")
        },
        toServe() {

            window.open(this.$store.getters.getBaseInfo.kefu)
        },



    }

}
</script>


<style lang="less" scoped>
.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 30px;
}

.col {
    width: 80%;
}

.col-auto {
    justify-content: center;
    align-items: center;
    display: flex;
    // width: 20%;
}

#casino .content>div {
    padding-top: 0;
}

#casino {
    border-top: none;
    border-bottom: none;
    background-color: transparent;
}

#casino .content:nth-child(1) {
    overflow: hidden;
    min-height: 90px;
}

.container {
    padding-top: 0;
}

.col-auto .btn {
    display: flex;
    align-items: center;
}

.van-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.grabSuccess {
    border-radius: 1rem;
}

.grabSuccess .title {
    height: 2.586667rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative
}

.grabSuccess .title:after,
.grabSuccess .title:before {
    content: "";
    width: .893333rem;
    height: .386667rem;
    background-size: 100%;
    position: absolute;
    top: 1.093333rem
}

.grabSuccess .records_tabs_box .records_tabs_box_top {
    font-size: 1rem;
    color: #9e9e9e;
    font-weight: 400;
    text-align: left;
    font-family: PingFangSC-Regular, PingFang SC
}

.grabSuccess .records_tabs_box .records_tabs_box_img {
    width: 1.733333rem;
    height: auto;
    position: absolute;
    top: .266667rem;
    right: .133333rem
}

.grabSuccess .records_tabs_box .records_tabs_box_des {
    background: #f2f2f2;
    border-radius: .133333rem;
    margin: .266667rem 0;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    height: 5rem;
}

/* .grabSuccess .records_tabs_box .records_tabs_box_des .tabs_box_des_img {
            width: 4.8rem;
            height: 2.4rem;
        } */

.grabSuccess .records_tabs_box .records_tabs_box_des .tabs_box_des_img>img {
    width: 4.8rem;
    height: 4.8rem;
    -o-object-fit: cover;
    object-fit: cover
}

.grabSuccess .records_tabs_box .records_tabs_box_des .tabs_box_des_r {
    text-align: left;
    padding: .266667rem;
    font-size: .266667rem
}

.grabSuccess .records_tabs_box .records_tabs_box_des .tabs_box_des_r .tabs_box_des_r_tit {
    overflow: hidden;
    font-size: .75rem;
    color: #424242;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    font-family: PingFangSC-Regular, PingFang SC
}

.grabSuccess .records_tabs_box .tabs_box_des_r_pic {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: .4rem;
    font-size: .373333rem;
    color: #424242
}

.grabSuccess .records_tabs_box .gray {
    color: #969696;
    font-size: .373333rem
}

.grabSuccess .records_tabs_box .txt1 {
    color: #ff9a2c;
    font-size: 1rem !important
}

.grabSuccess .tabs_btn {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center
}

.recharge_btn {
    border-width: 0;
    padding: .375rem .75rem;
    background-color: #fffeef;
}

.recharge_btn img {
    width: 11vw;
    display: flex;
    align-items: center;
}



.slotMachine .slot {
    height: 200px;
    background-position-x: 55%;
    background-repeat: no-repeat;
}

.slot1 {
    background-image: url("/img/au/order/slot1.jpg");
    background-size: 180px 180px;
}

.slot2 {
    background-image: url("/img/au/order/slot2.jpg");
    background-size: 180px 180px;
}

.slot3 {
    background-image: url("/img/au/order/slot3.jpg");
    background-size: 180px 180px;
}

.slot4 {
    background-image: url("/img/au/order/slot4.jpg");
    background-size: 180px 180px;
}

.slot5 {
    background-image: url("/img/au/order/slot5.jpg");
    background-size: 180px 180px;
}

.slot6 {
    background-image: url("/img/au/order/slot6.jpg");
    background-size: 180px 180px;
}

.slot7 {
    background-image: url("/img/au/order/slot7.jpg");
    background-size: 180px 180px;
}

.slot8 {
    background-image: url("/img/au/order/slot8.jpg");
    background-size: 180px 180px;
}

.slot9 {
    background-image: url("/img/au/order/slot9.jpg");
    background-size: 180px 180px;
}

.slot10 {
    background-image: url("/img/au/order/slot10.jpg");
    background-size: 180px 180px;
}

.slot11 {
    background-image: url("/img/au/order/slot11.jpg");
    background-size: 180px 180px;
}

.slot12 {
    background-image: url("/img/au/order/slot12.jpg");
    background-size: 180px 180px;
}

.slot13 {
    background-image: url("/img/au/order/slot13.jpg");
    background-size: 180px 180px;
}

.slot14 {
    background-image: url("/img/au/order/slot14.jpg");
    background-size: 180px 180px;
}

.slot15 {
    background-image: url("/img/au/order/slot15.jpg");
    background-size: 180px 180px;
}

.slot16 {
    background-image: url("/img/au/order/slot16.jpg");
    background-size: 180px 180px;
}

.slot17 {
    background-image: url("/img/au/order/slot17.jpg");
    background-size: 180px 180px;
}

.slot18 {
    background-image: url("/img/au/order/slot18.jpg");
    background-size: 180px 180px;
}

.slotMachine {
    width: 180px;
    border: 1px solid #6d00be;
    height: 180px;
    display: inline-block;
}

#casino .content>div {
    // padding-top: 165px;
    display: flex;
    width: 92vw;
    margin: 20px auto;
    flex-wrap: nowrap;
    justify-content: space-evenly;
}

.yongjin {
    padding: 30px;
    background: linear-gradient(90deg, #6529c9, #cc2996);
    color: #fff;
    border-radius: 20px;
    margin: 0 30px;
    margin-top: 30px;
    width: calc(100% - 60px);
}

.yonngjin_header {
    display: flex;
    margin-bottom: 50px;
}

.yonngjin_header div {
    font-size: 32px;
}

.yongjin_content {
    font-size: 28px;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
}

.yongjin_num {
    background: #fff;
    margin: 20px;
    padding: 30px;
}

.yongjin_num_header {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    color: #999;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
    font-size: 26px;
    text-align: center;
}

.auhomepage {
    height: 100vh;
}

.yongjin_num_btn {
    background: linear-gradient(90deg, #6529c9, #cc2996);
    color: #fff;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50px;
    margin-top: 20px;
    font-size: 32px;
    margin-bottom: 30px;
    // margin-top: 50px;
}


.group_cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    font-size: 24px;
}

.group_left {
    color: #333;

}

.group_right {
    color: #999;
}

.order_list_popup {
    width: 90%;
    border-radius: 20px;
    padding: 30px;
}

.order_list_popup_title {
    font-size: 32px;
    height: 50px;
    line-height: 50px;
    // text-align: center;
}

::v-deep .van-popup__close-icon {
    font-size: 40px;
}

::v-deep .van-popup__close-icon--top-right {
    top: 30px;
    right: 30px;
}

.order_list_popup_btn {
    width: 300px;
    box-shadow: 0px 0px 20px 2px #ccc;
    background: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    text-align: center;
    font-size: 24px;
    margin: 0 10px;
}

.order_list_popup_btn_active {
    background: #6100b3;
    color: #fff;
}
.imsggg img{
    display: inline-block;
}
</style>
