<template>
    <div class="auhomepage">

        <van-nav-bar class="nav-bar">
            <template #left>
                <!-- <van-icon name="arrow-left" color="#fff" @click="back()" /> -->
                <van-icon name="wap-home-o" @click="toHome" color="#333" size="36" />
            </template>
            <template #right>
                <img style="width: 36px;" @click="toServe" src="/img/au/kefu.png" />
            </template>
        </van-nav-bar>
        <div class="tabs">
            <div class="tabs_item" :class="{ 'tabs_item_active': tabsList_index == index }" @click="tabs_change(index)"
                v-for="(item, index) in tabsList" :key="index">
                {{ item.name }}
            </div>
        </div>


        <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
            :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
            <van-list class="hot-xuanfei" v-model="loading" :finished="finished" :offset="50" :immediate-check="false"
                :finished-text="$t('video.no_more')" @load="onLoad" :loading-text="$t('refresh.loading')">
                <div class="order_list" v-for="(item, index) in list" :key="index"
                    @click="order_show_data = item; order_show = true">
                    <div style="display: flex;justify-content: space-between;align-items: center;">

                        <div class="order_list_time">{{ dayjs(item.create_time * 1000).format("YYYY-MM-DD") }}</div>
                        <div class="order_list_status">{{ tabsStatus[item.status] }}{{ $t('xuanfei.status') }}</div>
                    </div>
                    <div v-if="item.goods.length > 0" style="display: flex;align-items: center;margin-top: 20px;">
                        <img class="order_list_img" :src="item.goods.pic" alt="">
                        <div class="order_list_name">{{ item.goods.name }}</div>
                    </div>
                    <div class="group_cell">

                        <div class="group_left">{{ $t('xuanfei.ddh') }}</div>
                        <div class="group_right">{{ item.expect }}</div>
                    </div>
                    <div class="group_cell">

                        <div class="group_left">{{ $t('xuanfei.sl') }}</div>
                        <div class="group_right">{{ $t("reservation.unit") }}{{ item.profit }}</div>
                    </div>
                    <div class="group_cell">

                        <div class="group_left">{{ $t('xuanfei.订单金额') }}</div>
                        <div class="group_right">{{ $t("reservation.unit") }}{{ item.money }}</div>
                    </div>
                    <template v-if="userInfo && item.status == 0">

                        <div class="group_cell" v-if="item.money > userInfo.money">

                            <div class="group_left">{{ $t('xuanfei.ce') }}</div>
                            <div class="group_right">{{ $t("reservation.unit") }}{{ (Number(item.money) - userInfo.money).toFixed(2) }}</div>
                        </div>
                    </template>

                    <div class="order_list_btn" v-if="item.status == 0">{{ $t('xuanfei.order_btn') }}</div>
                </div>
            </van-list>

        </van-pull-refresh>

        <van-popup v-model="order_show" v-if="order_show_data" class="order_list_popup" closeable
            :close-on-click-overlay="false">
            <div class="order_list_popup_title">
                {{ $t('xuanfei.status') }}
            </div>
            <div class="group_cell">
                <div class="group_left">{{ $t('xuanfei.order_time') }}</div>
                <div class="group_right">{{ dayjs(order_show_data.create_time * 1000).format("YYYY-MM-DD") }}</div>
            </div>
            <div class="group_cell">
                <div class="group_left">{{ $t('xuanfei.ddh') }}</div>
                <div class="group_right">{{ order_show_data.expect }}</div>
            </div>

            <div style="display: flex;align-items: center;margin-top: 20px;background: #f2ecf3;">
                <img class="order_list_img" :src="order_show_data.goods.pic" alt="">
                <div class="order_list_name">{{ order_show_data.goods.name }}</div>
            </div>
            <div class="group_cell">

                <div class="group_left">{{ $t('withdraw.money') }}</div>
                <div class="group_right">{{ order_show_data.money }}</div>
            </div>
            <div class="group_cell">

                <div class="group_left">{{ $t('xuanfei.sl') }}</div>
                <div class="group_right" style="color: #ffb66b;">{{ order_show_data.profit }}</div>
            </div>

            <template v-if="userInfo">

                <div class="group_cell" v-if="order_show_data.money > userInfo.money">

                    <div class="group_left">{{ $t('xuanfei.ce') }}</div>
                    <div class="group_right" style="color: #ffb66b;" v-if="userInfo">{{ $t("reservation.unit") }}{{
                        userInfo.money - Number(order_show_data.money) }}</div>
                </div>
            </template>

            <div style="display: flex;align-items: center;margin-top: 20px;">
                <div class="order_list_popup_btn" @click="order_show = false">{{ $t('xuanfei.qx') }}</div>
                <div class="order_list_popup_btn order_list_popup_btn_active" v-if="order_show_data.status == 0"
                    @click="order_pay">{{ $t('xuanfei.order_btn') }}</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
    data() {
        return {
            dayjs,
            tabsList_index: 0,
            userInfo: null,
            tabsList: [{
                name: this.$t("index.all"),
                id: ''
            }, {
                name: this.$t("xuanfei.dfk"),
                id: 0
            }, {
                name: this.$t("xuanfei.ywc"),
                id: 1
            }, {
                name: this.$t("xuanfei.dj"),
                id: 2
            }],
            tabsStatus: {
                0: this.$t("xuanfei.dfk"),
                1: this.$t("xuanfei.ywc"),
                2: this.$t("xuanfei.dj")
            },

            loading: false,
            finished: false,
            isLoading: true,
            list: [],
            order_show: false,
            order_show_data: null,
            page: 1
        }
    },
    created() {
        this.$http({
            method: 'get',
            url: 'user_info'
        }).then(res => {
            if (res.code === 200) {
                this.userInfo = res.data;
            } else if (res.code === 401) {
                this.$toast(res.msg);
            }
        })
        this.getNhomeList()

    },
    methods: {
        order_pay() {
            this.$http({
                method: 'get',
                url: 'doplaceOrder',
                data: {
                    order_id: this.order_show_data.id
                }
            }).then(res => {
                if (res.code === 200) {
                    console.log(res.data, 777)
                    this.order_show = false;
                    this.order_show_data = null
                } else if (res.code === 401) {
                    this.$toast({
                        message: res.msg,
                        duration: 3000
                    });
                    this.order_show = false;
                    this.order_show_data = null
                }
            })
        },
        tabs_change(index) {
            this.tabsList_index = index
            this.page = 1;
            this.list = []
            this.getNhomeList()
        },
        toHome() {
            this.$router.push("/")
        },
        toServe() {

            window.open(this.$store.getters.getBaseInfo.kefu)
        },


        getNhomeList() {
            let params = {
                page: this.page
            }
            console.log(this.tabsList, 666)
            if (this.tabsList_index != 0) {
                params.status = this.tabsList[this.tabsList_index].id
            }
            this.$http({
                method: 'get',
                url: 'orderlist',
                data: params,
            }).then(res => {
                console.log(res.data, 999)
                this.loading = false;
                if (this.page == 1) {
                    this.list = res.data;
                } else {

                    this.list = this.list.concat(res.data);
                }
                if (res.data.length == 0) {
                    this.finished = true;
                } else {
                    this.finished = false;
                }
                this.count = res.data.count;
                this.page++;
            })


        },
        onRefresh() {
            setTimeout(() => {
                this.page = 1
                this.getNhomeList();
                this.isLoading = false;
                this.$toast(this.$t('reservation.refresh'));
            }, 500);
        },
        onLoad() {
            this.loading = true;
            this.getNhomeList();
            let timer = setTimeout(() => {
                if (this.refreshing) {
                    this.list = [];
                    this.refreshing = false;
                }
                // this.loading = false;
                if (this.list.length === this.count) {
                    this.finished = true;
                }
                this.finished && clearTimeout(timer);//清除计时器
            }, 500);
        },
    }
}
</script>

<style lang="less" scoped>
.tabs {
    display: flex;
    // flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    margin-top: 30px;
}

.tabs_item {
    // flex: 1;
    padding: 10px 10px;
    border: 1px solid transparent;
    color: #6d00be;
    border-radius: 50px;
    font-size: 24px;
    // width: 20%;
    text-align: center;
}

.tabs_item_active {
    border: 1px solid #6d00be;
}

.auhomepage {
    height: 100vh;
    overflow: auto;
}


.order_list {
    margin: 30px;
    padding: 30px;
    background: #fff;
    border-radius: 20px;
}

.order_list_time {
    font-size: 22px;
}

.order_list_status {
    padding: 10px 20px;
    background: #ffb10c;
    border-radius: 20px;
    display: inline-block;
    text-align: center;
    color: #fff;
    font-size: 22px;
}

.order_list_img {
    width: 120px;
    height: 120px;
    border-radius: 10px;
    margin-right: 20px;
}

.order_list_name {
    width: calc(100% - 130px);
}

.group_cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    font-size: 24px;
}

.group_left {
    color: #333;

}

.group_right {
    color: #999;
}

.order_list_btn {
    background: #6600b1;
    border-radius: 50px;
    width: 100%;
    color: #fff;
    font-size: 28px;
    padding: 30px 0;
    text-align: center;
    margin-top: 30px;
}

.order_list_popup {
    width: 90%;
    border-radius: 20px;
    padding: 30px;
}

.order_list_popup_title {
    font-size: 32px;
    height: 50px;
    line-height: 50px;
    // text-align: center;
}

::v-deep .van-popup__close-icon {
    font-size: 40px;
}

::v-deep .van-popup__close-icon--top-right {
    top: 30px;
    right: 30px;
}

.order_list_popup_btn {
    width: 300px;
    flex: 1;
    box-shadow: 0px 0px 20px 2px #ccc;
    background: #fff;
    padding: 10px 20px;
    border-radius: 50px;
    text-align: center;
    font-size: 24px;
    margin: 0 10px;
}

.order_list_popup_btn_active {
    background: #6100b3;
    color: #fff;
}
</style>