<template>
    <div>
        <van-nav-bar class="nav-bar">
            <template #left>
                <!-- <van-icon name="arrow-left" color="#fff" @click="back()" /> -->
                <!-- <van-icon @click="toHome" name="wap-home-o" color="#333" size="36" /> -->
            </template>
            <template #right>
                <!-- <img @click="toServe" style="width: 36px;" src="/img/au/kefu.png" /> -->
            </template>
        </van-nav-bar>
        <div class="wrapper homepage">
            <div class="ifm">
                <div class="left">
                    <div><span class="welcome">{{ $t("xuanfei.welcome") }}</span></div>
                    <div style="font-size: 1rem;"><span v-if="userInfo">{{ userInfo.username }}</span></div>
                </div>
                <div class="right" style="width: 50%;"></div>
                <div class="logo" v-if="$store.getters.getBaseInfo.ico">
                <van-image :src="$store.getters.getBaseInfo.ico" fit="cover"
                        style="width: 70vw;height: 80px;
    position: relative;
    margin-left: 17vh;
    margin-top: -11vh;"
/>
                    
                </div>
            </div>

            <div class="carousel">
                <div class="carousel-inner"
                    style="transform: translateX(-4400px); cursor: grab; transition: transform 0.3s;">
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_01.jpeg" alt="图片1" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 17px;">【職業】美容師</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【恵比寿】王道のきれい系女性。</p>
                            <p style="font-size: 15px;">モデルと間違われるほどの抜群のスタイル。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_02.jpeg" alt="图片2" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 17px;">【職業】受付</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">「笑顔が素敵でかわいい系女性。」</p>
                            <p style="font-size: 15px;">彼女の魅力は無限大。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_03.jpeg" alt="图片3" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 17px;">【職業】エステティシャン</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【渋谷】ニット越しでも伝わるグラマラスボディー。</p>
                            <p style="font-size: 15px;">メリハリのあるスタイルはモデル顔負け。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_04.jpeg" alt="图片4" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】ネイリスト</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【名古屋】レースクイーン並みの美脚の持ち主。</p>
                            <p style="font-size: 15px;">日常生活の中では出会えないタイプの女性です。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_05.jpeg" alt="图片5" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】グラビア</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【難波】「スタイル抜群な和風美女。」</p>
                            <p style="font-size: 15px;">古き良き日本女性のおしとやかさを兼ね備えた女性です。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_06.jpeg" alt="图片6" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】モデル</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【横浜】「クールビューティー系美女。」</p>
                            <p style="font-size: 15px;">一見、話しかけづらそうに思われがちだが、話すと人当たり良く、笑顔の素敵な女性です。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_07.jpeg" alt="图片7" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】学生</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【池袋】「一流大学ご在学中。」</p>
                            <p style="font-size: 15px;">才色兼備な女性とはまさに彼女のこと。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_08.jpeg" alt="图片8" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】リフレクソロジスト</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【麻布十番】「モデル並みのスタイル。」</p>
                            <p style="font-size: 15px;">彼女の歩く姿はまるでランウェイを彩るように映る。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_09.jpeg" alt="图片9" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】栄養士</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【渋谷】甘えん坊系女子。</p>
                            <p style="font-size: 15px;">童顔好き、幼い系好き、ロリ好きのあなた。期待は裏切りません。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_10.jpeg" alt="图片10" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】OL</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【代官山】「穏やかで、大人の落ち着きがある美女。」</p>
                            <p style="font-size: 15px;">彼女の美はあなたの心にひと時の安らぎをもたらします。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_11.jpeg" alt="图片11" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】レースクイーン</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【青山】ロングヘアがお似合いの美女。</p>
                            <p style="font-size: 15px;">容姿端麗とは彼女のためにつくられたフレーズである。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_12.jpeg" alt="图片12" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】OL</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【自由が丘】「メリハリＢＯＤＹがセクシー。」</p>
                            <p style="font-size: 15px;">彼女の体は曲線美の調和。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_13.jpeg" alt="图片13" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】看護師</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【目黒】「目元がチャームポイント♪」</p>
                            <p style="font-size: 15px;">彼女の瞳は魅惑の入り口。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_14.jpeg" alt="图片14" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】歯科衛生士</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【梅田】「美脚が魅力のスレンダー女性。」</p>
                            <p style="font-size: 15px;">すれ違う人々の視線を釘付けにすること間違いありません。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_15.jpeg" alt="图片15" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】OL</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【新宿】「セクシーすぎるＯＬさん。」</p>
                            <p style="font-size: 15px;">部下との禁断の関係を味わうような背徳感をご堪能ください。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_16.jpeg" alt="图片16" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】エステティシャン</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【品川】「癒し系美人。」</p>
                            <p style="font-size: 15px;">彼女の存在があなたの心にオアシスをつくります。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_17.jpeg" alt="图片17" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】美容師</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【名古屋】「綺麗でスベスベなお肌が魅力。」</p>
                            <p style="font-size: 15px;">手入れの行き届いた容姿。圧倒的な美を兼ね備えた女性です。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_18.jpeg" alt="图片18" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】受付</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【渋谷】「煌びやかな美貌にスレンダーな体型。」</p>
                            <p style="font-size: 15px;">彼女の美は輝きと線の美。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_19.jpeg" alt="图片19" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】WEBデザイナー</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【川崎】「黒髪が似合うボブヘア美女。」</p>
                            <p style="font-size: 15px;">ビジュアルのみならず、スタイルも会員トップクラスの女性。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_20.jpeg" alt="图片20" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】栄養士</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【博多】「女性らしい可憐さと小悪魔的な天真爛漫さ。」</p>
                            <p style="font-size: 15px;">彼女の魅力は独特なる両極端。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_21.jpeg" alt="图片21" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】OL</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【中目黒】「エキゾチックで優しげな美人女性。」</p>
                            <p style="font-size: 15px;">良い意味で日本人女性らしくない性格で楽しいひと時に花を添えます。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_22.jpeg" alt="图片22" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】ネイリスト</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【秋葉原】「育ちの良さが滲み出ている笑顔が魅力。」</p>
                            <p style="font-size: 15px;">彼女の微笑みにあなたの心は揺さぶられるでしょう。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_23.jpeg" alt="图片23" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】OL</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【博多】「一流大学ご在学中のインテリ女性。」</p>
                            <p style="font-size: 15px;">知性と美貌を兼ね備えた彼女とのひと時をお楽しみください。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_24.jpeg" alt="图片24" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】歯科衛生士</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【名古屋】「スタイル抜群な和風美人。」</p>
                            <p style="font-size: 15px;">和風美人な彼女からは心を和ませる癒しを感じるでしょう。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_25.jpeg" alt="图片25" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】家事手伝い</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【三軒茶屋】「制御不能なわがままボディー。」</p>
                            <p style="font-size: 15px;">制御できるのはあなただけかもしれません。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_26.jpeg" alt="图片26" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】学生</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【池袋】どこかで会ったことがあるような</p>
                            <p style="font-size: 15px;">週刊誌のグラビア表紙で見たようなそんな二面性を持ち合わせた女性。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_27.jpeg" alt="图片27" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】塾講師</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【豊洲】「穏やかな口調とおしとやかな雰囲気の落ち着きある大人美人。」</p>
                            <p style="font-size: 15px;">彼女の美はあなたの心に安らぎを与えてくれます。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_28.jpeg" alt="图片28" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】美容師</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【高田馬場】「スレンダーな美脚の持ち主。」</p>
                            <p style="font-size: 15px;">笑った時に出るえくぼがチャームポイント。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_29.jpeg" alt="图片29" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】アイドル</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【中野】「相手を笑顔にしてしまう笑顔がキュートな女性。」</p>
                            <p style="font-size: 15px;">彼女の眼差しは禁断の恋愛の誘惑。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_30.jpeg" alt="图片30" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】保育士</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【川崎】「美白なら会員トップクラス。」</p>
                            <p style="font-size: 15px;">女性の肌の質感にこだわりをお持ちの方にはおすすめです。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_31.jpeg" alt="图片31" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】OL</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【上野】「笑顔がキュートな童顔OLさん。」</p>
                            <p style="font-size: 15px;">彼女の笑顔はあなたの日々の疲れを癒してくれます。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_32.jpeg" alt="图片32" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】講師</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【錦糸町】「癒し系美魔女。」</p>
                            <p style="font-size: 15px;">彼女の癒しは言葉ではなく、醸し出す雰囲気から溢れ出たもの。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_33.jpeg" alt="图片33" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】販売員</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【横浜】「美肌系のエレガントなお姉さま。」</p>
                            <p style="font-size: 15px;">メンテナンスし尽くされたお肌と品のある微笑みであなたを大人の世界へ誘惑してしまうかもしれません。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_34.jpeg" alt="图片34" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】介護福祉士</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【下北沢】「ロングスカートが似合うスレンダー大人女子。」</p>
                            <p style="font-size: 15px;">不意に見せる彼女の笑顔にあなたの心は射抜かれるでしょう。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_35.jpeg" alt="图片35" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】飲食店staff</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【新宿】「グラビアモデル並みのプロポーション。」</p>
                            <p style="font-size: 15px;">街ですれ違ったら思わず振り返るような彼女と楽しいひと時をお過ごしください。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_36.jpeg" alt="图片36" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】アパレル販売</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【吉祥寺】「キュートとセクシーの二刀流。」</p>
                            <p style="font-size: 15px;">彼女の魅力はその2つの奇跡の融合。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_37.jpeg" alt="图片37" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】クリエイター</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【恵比寿】「某有名女子アナのような洗練された美人女性。」</p>
                            <p style="font-size: 15px;">まるで有名人と密会しているような禁断のひと時をお楽しみください。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_38.jpeg" alt="图片38" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】保育士</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【新小岩】「メリハリボディーとはまさに彼女のこと。」</p>
                            <p style="font-size: 15px;">外見はもちろん、内面も綺麗な黒髪美女。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_39.jpeg" alt="图片39" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】美容部員</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【渋谷】「小悪魔的な一面と、しっかりした一面をもつ美女。」</p>
                            <p style="font-size: 15px;">モデルさん顔負けの美脚であなたを禁断の恋愛へ。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_40.jpeg" alt="图片40" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】モデル</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【北千住】「クリっとした瞳がキュートな美人さん。」</p>
                            <p style="font-size: 15px;">グイグイ引っ張ってくれる男らしい男性にキュンとしてしまうそうです。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_41.jpeg" alt="图片41" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】ホームヘルパー</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【秋葉原】「黒髪が似合う日本美人。」</p>
                            <p style="font-size: 15px;">明るい性格ですぐ仲良く打ち解けられるのも彼女の魅力。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_42.jpeg" alt="图片42" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】レースクイーン</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【難波】「ハーフタレントのような美人さん。」</p>
                            <p style="font-size: 15px;">はっきりとした顔立ちが好みの方にはおすすめです。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_43.jpeg" alt="图片43" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】ネイリスト</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【池袋】「某アイドルグループにいた!?と思うような美人さん。」</p>
                            <p style="font-size: 15px;">愛嬌もあり、楽しい非日常空間をお楽しみください。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_44.jpeg" alt="图片44" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】アイドル</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【六本木】「某タレント似かつ、トップクラスの人気を誇る女性。」</p>
                            <p style="font-size: 15px;">相手に合わせたコミュニケーションが得意なので初めてさんにもおすすめ。</p>
                        </div>
                    </div>
                    <div class="carousel-item1">
                        <div><img src="/img/info_img_45.jpeg" alt="图片45" style="height: 300px;width: 180px;">
                            <h3 style="font-size: 20px;">【職業】OL</h3>
                            <p style="margin-bottom: 0px;font-size: 15px;">【二子玉川】「落ち着いた雰囲気の美魔女系。」</p>
                            <p style="font-size: 15px;">どんな相談でも受け止めてくれるような包容力も持ち合わせています。</p>
                        </div>
                    </div>
                </div>
                <button class="prev" @click="moveSlide(-1)">❮</button><button class="next"
                    @click="moveSlide(1)">❯</button>
            </div>


            <div style="height: 120px;"></div>

        </div>
    </div>
</template>

<script>
</script>
<script>

export default {
    data() {
        return {
            active_h: '',
            userInfo: null,
            list: [],
            moneylist: [],
            moveSlide:null
        }
    },
    created() {

        if (localStorage.getItem('token')) {
            this.getUserInfo();
            this.getBasicConfig()
            // this.$toast(this.$t("reservation.refresh"));
        } else {
            this.$router.push({ path: '/Login' })
        }
    },
    mounted() {
        
    let currentIndex = 0;
    let startX = 0;
    let isDragging = false;
    let totalItems;
    let itemWidth;

    this.moveSlide = function moveSlide(direction) {
        totalItems = document.querySelectorAll('.carousel-item1').length;
        currentIndex += direction;

        if (currentIndex < 0) {
            currentIndex = totalItems - 1;
        } else if (currentIndex >= totalItems) {
            currentIndex = 0;
        }

        updateCarouselPosition();
    }

    function updateCarouselPosition() {
        const carouselInner = document.querySelector('.carousel-inner');
        itemWidth = document.querySelector('.carousel-item1').offsetWidth;
        carouselInner.style.transform = `translateX(-${currentIndex * itemWidth}px)`;
    }

    document.addEventListener('DOMContentLoaded', () => {
        // 初始化位置
        updateCarouselPosition();

        // 自动轮播
        setInterval(() => {
            moveSlide(1); // 1 表示向右滑动
        }, 3000); // 每隔3秒滑动一次
    });

    // 添加鼠标按下、移动和松开事件来实现拖动
    const carouselInner = document.querySelector('.carousel-inner');

    carouselInner.addEventListener('mousedown', (event) => {
        startX = event.pageX;
        isDragging = true;
        carouselInner.style.cursor = 'grabbing'; // 显示抓取样式
    });

    carouselInner.addEventListener('mousemove', (event) => {
        if (!isDragging) return;
        
        const deltaX = event.pageX - startX;
        carouselInner.style.transition = 'none'; // 关闭平滑过渡效果
        carouselInner.style.transform = `translateX(${deltaX - currentIndex * itemWidth}px)`;
    });

    carouselInner.addEventListener('mouseup', (event) => {
        if (!isDragging) return;
        
        isDragging = false;
        const deltaX = event.pageX - startX;
        carouselInner.style.transition = 'transform 0.3s ease'; // 恢复过渡效果

        if (deltaX > 50) {
            // 向右滑动，上一张
            moveSlide(-1);
        } else if (deltaX < -50) {
            // 向左滑动，下一张
            moveSlide(1);
        } else {
            // 如果滑动幅度不足，恢复到当前页面
            updateCarouselPosition();
        }

        carouselInner.style.cursor = 'grab'; // 重置鼠标样式
    });

    // 防止鼠标离开时拖动状态未更新
    carouselInner.addEventListener('mouseleave', () => {
        if (isDragging) {
            isDragging = false;
            carouselInner.style.cursor = 'grab';
            carouselInner.style.transition = 'transform 0.3s ease'; // 恢复过渡效果
            updateCarouselPosition();
        }
    });

    // 支持触摸屏手势
    carouselInner.addEventListener('touchstart', (event) => {
        startX = event.touches[0].pageX;
        isDragging = true;
        carouselInner.style.cursor = 'grabbing';
    });

    carouselInner.addEventListener('touchmove', (event) => {
        if (!isDragging) return;
        
        const deltaX = event.touches[0].pageX - startX;
        carouselInner.style.transition = 'none';
        carouselInner.style.transform = `translateX(${deltaX - currentIndex * itemWidth}px)`;
    });

    carouselInner.addEventListener('touchend', (event) => {
        if (!isDragging) return;
        
        isDragging = false;
        const deltaX = event.changedTouches[0].pageX - startX;
        carouselInner.style.transition = 'transform 0.3s ease'; // 恢复过渡效果

        if (deltaX > 50) {
            // 向右滑动
            moveSlide(-1);
        } else if (deltaX < -50) {
            // 向左滑动
            moveSlide(1);
        } else {
            // 如果滑动幅度不足，恢复到当前页面
            updateCarouselPosition();
        }

        carouselInner.style.cursor = 'grab'; // 重置鼠标样式
    });
    },
    methods: {

        getBasicConfig() {
            this.$http({
                method: 'get',
                url: 'sys_config'
            }).then(res => {
                console.log(res.data)
                this.list = res.data.list
                this.moneylist = res.data.moneylist
            })

        },
        getUserInfo() {
            this.$http({
                method: 'get',
                url: 'user_info'
            }).then(res => {
                if (res.code === 200) {
                    this.userInfo = res.data;
                    this.menu_top = 15;
                    if (this.userInfo.status !== 1) {
                        this.$toast(this.$t("video.account_out"));
                        localStorage.clear()
                        this.$router.push({ path: '/Login' })
                    }
                } else if (res.code === 401) {
                    this.$toast(res.msg);
                }
            })
        },
        toHome() {
            this.$router.push("/");
        },
        toServe() {
            window.open(this.$store.getters.getBaseInfo.kefu)
        },

        doWithdrawal() {

            if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
                this.$toast.fail(this.$t("setting.doWithdrawal"));
            } else {
                this.$http({
                    method: 'get',
                    url: 'user_get_bank'
                }).then(res => {
                    if (res.data.is_bank) {
                        this.$router.push("/withdraw");
                    } else {
                        this.$router.push("/Setbank");
                        this.$toast.fail(this.$t("setting.set_bank"));
                    }
                })
            }


        },
        doPay() {

            if (!localStorage.getItem('token')) {
                this.$router.push({ path: '/Login' })
            }
            if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
                this.$toast.fail(this.$t("setting.doPay"));
            } else {
                this.$router.push({
                    name: 'Recharge'
                })
            }
        },
        toUrl(url) {

            if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
                this.$toast.fail(this.$t("setting.doPay"));
            }

            if (localStorage.getItem('token')) {
                // this.$router.push({ path: '/Infomation' });

                this.$router.push(url)
            } else {
                this.$router.push({ path: '/Login' })
            }
            // if (this.$store.getters.getBaseInfo.close_withdraw == 1) {
            //     this.$toast.fail(this.$t("setting.doPay"));
            // } else {
            //     this.$router.push({
            //     name: 'Recharge',
            //     params: {
            //         'balance': this.userInfo.money
            //     }
            //     })
            // }
        }
    }
}
</script>
<style lang="less" scoped>
.ifm {
    display: flex;
    padding: 1.5vh 20px;
    background-color: #ffb900;
    flex-direction: column;
    margin-bottom: 20px;
}

.welcome {
    font-size: 3vh;
    font-weight: 600;
}

.ifm div {
    padding-top: 1vh;
}


.ifm img {
    /* display: flex;
			width: 40px;
			height: 40px;
			border-radius: 50%; */
}

.logo {
    display: flex;
    justify-content: center;
}

.container {
    width: 92%;
    color: #fff;
    background: linear-gradient(90deg, #6529c9, #cc2996);
    border-radius: 22px;

    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
}

.ifmList {
    border-radius: 0 0 16px 16px;
    padding: 0 20px;
    box-sizing: border-box;
}

.ifmList ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 160px;
}

.ifmList ul li {
    text-align: left;
    font-size: 24px;
}

.ifmList ul li .num {
    font-size: 28px;
}

.ifmList ul li .tips {
    font-size: 28px;
    margin-top: 20px;
}

.tabsList {
    display: flex;
    align-items: center;
    width: 20vh;
    width: 92vw;
    justify-content: space-evenly;
    margin: 4vh 4vw 0 4vw;
    padding: 2vh 0vw;
    background-color: #fff;
    border-radius: 22px;
    flex-wrap: wrap;
}

.tabsList ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.tabsList ul li {
    width: 33.33%;
    font-size: 28px;
    text-align: center;
    margin-bottom: 10px;
    padding: 14px 20px;
}

.tabsList ul li div {
    color: #333;
    font-size: 24px;
}

.homepage {
    background: #fffeef !important;
}

.wrapper {
    height: auto;
    width: 100%;
    position: relative;
    transition: all ease 0.5s;
    -webkit-transition: all ease 0.5s;
    -moz-transition: all ease 0.5s;
    -ms-transition: all ease 0.5s;
    margin-left: 0;
    z-index: 1;
    // min-height: 100vh;
    /* padding-bottom: 80px; */
}

.titleList {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    box-sizing: border-box;
    width: 92vw;
    height: 10vh;
    margin: 0 4vw;
    flex-direction: row;
}

.titleList>.name {
    display: flex;
    height: 7vh;
    color: #333333;
    font-size: 32px;
    font-weight: 700;
    border-radius: 20px;
    width: 44vw;
    background-color: #fff;
    justify-content: flex-end;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
}

.titleList>.name img {
    width: 11vw;
}

.titleList>.name span {
    line-height: 4vh;
}


.actives {
    background: #ffb900 !important;
}

.rockList {
    margin-top: 100px;
}


.list {
    /* padding:0 20px; */
    /* margin-top:20px; */
}

.list,
.val {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f5f5f5;
    padding: 20px;
    width: 100%;
    /* padding:0 20px; */
}

.list>.val>.lef {
    margin-right: 16px;
    display: inline-block;
    width: 100px;
    height: 100px;
    overflow: hidden;
}

.list>.val>.lef>img {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    /*background: red;*/


}

.list>.val>.rig>.nav {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 12px;
}

.list>.val>.rig>.navVal {
    font-size: 24px;

    margin-bottom: 12px;
}


.carousel {
    position: relative;
    margin: -40px 0;
    overflow: hidden;
    height: 92vh;
    color: #333;
}

.carousel h3 {
    margin: 20px 0;
    margin-top: 0;
    font-weight: 400;

}

.carousel-item1 p {
    font-size: 24px !important;
    margin: 10px 0;
}

.carousel-inner {
    width: 50000%;
    display: flex;
    transition: transform 0.5s ease-in-out;
}

.carousel-item1 {
    width: 50vw;
    padding: 20px;
    height: 80vh;
}

.carousel-item1 img {
    width: 100% !important;
    border-radius: 16px;
    display: block;
}

.carousel-caption {
    text-align: center;
    margin-top: 20px;
    font-size: 32px;
    color: #333;
}

.prev {
    left: 20px;
}

.next {
    right: 20px;
}

.carousel button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 20px;
    cursor: pointer;
    font-size: 48px;
    border-radius: 50%;
    z-index: 10;
}
</style>