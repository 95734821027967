<template>
  <div class="bg-container">

    <van-nav-bar class="nav-bar">

      <template #left>
        <van-icon @click="toHome" name="wap-home-o" color="#333" size="36" />
      </template>
      <template #title>
        <van-image style="height: 78px; margin: 10px 0px 0px;" :src="$store.getters.getBaseInfo.ico" />
      </template>
      <template #right>
        <img class="login-between-div" style="width: 30px;" src="/img/au/kefu.png" @click="toKefu" alt="" srcset="">

        <div v-if="lang" class="language" @click="$router.push('/language')">
          <img :src="require('../images/language/' + lang + '.png')" />
        </div>
      </template>
    </van-nav-bar>

    <img class="bg-img" v-if="$store.getters.getBaseInfo.login_bg" :src="$store.getters.getBaseInfo.login_bg" />
    <div class="bg-wrapper">

      <div class="login">
        <div class="wrapper">
          <!-- <div class="logo-container">
            <div class="logo-wrapper">
              <img
                class="logo-img"
                style="width: 75vw; height: 17vh;"
                :src="
                  this.$store.getters.getBaseInfo.ico !== undefined
                    ? this.$store.getters.getBaseInfo.ico
                    : '/img/null.png'
                "
              />
            </div>
          </div> -->
          <div class="logo"><img :src="this.$store.getters.getBaseInfo.ico !== undefined
            ? this.$store.getters.getBaseInfo.ico
            : '/img/null.png'
            " style="width: 75vw; height: 17vh;"></div>
          <!-- <div class="title">{{ this.$store.getters.getBaseInfo.name}}</div> -->
          <div class="loginForm">
            <van-field v-model="username" clearable input-align="center" class="input"
              :placeholder="$t('auth.username_place')" />
            <van-field v-model="password" :type="passwordType" input-align="center" class="input"
              :placeholder="$t('auth.pwd_place')">
              <template slot="right-icon">
                <van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
              </template>
            </van-field>
            <div class="reset-text" @click="noPsw">
              <span>{{ $t("auth.forgetpwd") }}?</span>
            </div>
            <div @click="toRegister()" class="register-text">
              <span>{{ $t("auth.no_account") }}</span> <span style="color: #f24255;">{{ $t("auth.no_account_login") }}</span>
            </div>
            <van-button class="login-btn" type="primary" size="normal" :disabled="isDisabled" @click="doLogin()">{{
              $t("auth.login") }}</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "inputValue",
    event: "input",
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      username: "",
      lang: '',
      password: this.inputValue,
      passwordType: "password",
      isDisabled: false
    };
  },
  mounted() {
    // localStorage.setItem("lang", 'ms_my');
    this.lang = localStorage.getItem("viodeLang") || this.$store.state.langDefault;
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },

    toKefu() {

      if (this.$store.getters.getBaseInfo.kefu) {

        window.open(this.$store.getters.getBaseInfo.kefu)
      }
    },
    switchPasswordType() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    back() {
      this.$router.push('/')
      // return window.history.back();
    },
    noPsw() {

      this.$toast(this.$t("auth.customerService"));
    },
    toRegister() {
      this.$router.push("Register");
    },
    doLogin() {
      if (
        this.username === "" ||
        this.username === null ||
        this.username === undefined
      ) {
        this.$toast(this.$t("auth.username_place"));
        return false;
      }
      if (
        this.password === "" ||
        this.password === null ||
        this.password === undefined
      ) {
        this.$toast(this.$t("auth.pwd_place"));
        return false;
      }
      this.isDisabled = true
      this.$http({
        url: "member_login",
        method: "post",
        data: {
          username: this.username,
          password: this.password,
          lang: this.lang
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("tokensso", res.data.tokensso);
          localStorage.setItem("token", res.data.id);
          this.$router.push("/au/auMine");
        } else {
          this.$toast(res.msg);
        }
        this.isDisabled = false
      });
    },
  },
  created() {
    this.$http({
      method: 'get',
      url: 'base_info'
    }).then(res => {
      if (res.code == 200) {

        if (!localStorage.getItem('token') && res.data.video_login == 1) {
          this.$router.push({ path: '/Login' })
        }
        this.$store.commit('setBaseInfoValue', res.data);
      }
    })
    if (localStorage.getItem("token")) {
      return window.history.back();
    }
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.login {
  height: 100%;
}

.bg-container .bg-wrapper .login .nav-bar {
  background: 0 0;
}

.language {
  // position: absolute;
  // top: 4px;
  // right: 0;
  margin-left: 20px;
  height: 60px;

  img {
    height: 100%;
    border-radius: 50%;
  }
}

.login .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.logo-container {
  margin: 0 auto;
  width: 45%;
}

.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 62.5%;
}

.logo-container .logo-wrapper .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  opacity: 0.8;
}

.login .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 60px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}

.login .wrapper .loginForm {
  top: 0;
  position: absolute;
  margin-top: 35vh;
  border-radius: 50px;
  opacity: .85;
  padding: 0 10vw;
  box-sizing: border-box;
  width: 100vw;

  // padding: 60px;
}

.login .wrapper .loginForm .input {
  padding: 10px 20px;
  margin-top: 40px;
  border-radius: 50px;
  text-align: center;
  line-height: 80px;
  font-size: 30px;
  color: #4e4e4e;
}

::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}

::v-deep .van-icon {
  font-size: 50px;
}

.login .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.login .wrapper .loginForm .reset-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}

.login .wrapper .loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login .wrapper .loginForm .register-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}

.login .wrapper .loginForm .active {}

.login .wrapper .loginForm .login-btn {
  margin-top: 85px;
  width: 100%;
  height: 100px;
  border-radius: 50px;
  color: #fff;

  background: linear-gradient(90deg, #6529c9, #cc2996);
  font-size: 30px;
  font-weight: bolder;
  border: none;
}

.logo {
  position: absolute;
  width: 90vw;
  top: 20vh;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 5vw;
}
</style>
