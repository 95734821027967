<template>

  <div class="bg-container">

    <van-nav-bar class="nav-bar">

      <template #left>
        <!-- <van-icon @click="toHome" name="wap-home-o" color="#333" size="36" /> -->
        <van-icon name="arrow-left" color="#333" @click="back()" />
      </template>
      <!-- <template #title>
        <van-image style="height: 78px; margin: 10px 0px 0px;" :src="$store.getters.getBaseInfo.ico" />
      </template> -->
      <template #right>
        <img class="login-between-div" style="width: 30px;" src="/img/au/kefu.png" @click="toKefu" alt="" srcset="">

        <div v-if="lang" class="language" @click="$router.push('/language')">
          <img :src="require('../images/language/' + lang + '.png')" />
        </div>
      </template>
    </van-nav-bar>
    <img class="bg-img" v-if="$store.getters.getBaseInfo.login_bg" :src="$store.getters.getBaseInfo.login_bg" />
    <div class="bg-wrapper">
      <div class="register">
        <!-- <van-nav-bar class="nav-bar">
          <template #left>
            <van-icon name="arrow-left" color="#fff" @click="back()" />
          </template>
        </van-nav-bar> -->
        <div class="wrapper">
          <!-- <div class="logo-container">
            <div class="logo-wrapper">
              <img class="logo-img" :src="this.$store.getters.getBaseInfo.ico !==undefined ?this.$store.getters.getBaseInfo.ico:''">
            </div>
          </div> -->
          <!-- <div class="title">{{$t("auth.register")}}</div> -->

          <div class="logo"><img :src="this.$store.getters.getBaseInfo.ico !== undefined
            ? this.$store.getters.getBaseInfo.ico
            : '/img/null.png'
            " style="width: 75vw; height: 17vh;"></div>
          <div class="loginForm">
            <div class="names">* {{$t('auth.username')}}</div>
            <van-field v-model="username" clearable input-align="center" class="input"
              :placeholder="$t('auth.username_place')" />
            <div class="names">* {{$t('auth.pwd')}}</div>
            <van-field v-model="password" :type="passwordType" input-align="center" class="input"
              :placeholder="$t('auth.pwd_place')">
              <template slot="right-icon">
                <van-icon :name="passwordType === 'password' ? 'closed-eye' : 'eye-o'" @click="switchPasswordType" />
              </template>
            </van-field>
            <div class="names">* {{$t('auth.invite_code')}}</div>
            <van-field v-model="code" clearable input-align="center" class="input"
              :placeholder="$t('auth.invite_code_place')" />
            <div class="agreement">
              <van-checkbox v-model="checked" />
              <span class="agreement-text">{{ $t("auth.agreement_place") }}</span>
            </div>
            <van-button class="login-btn" :disabled="isDisabled" type="primary" size="normal"
              @click="doRegister()">{{ $t("auth.register") }}</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'inputValue',
    event: 'input'
  },
  props: {
    /**
     * 当前输入的值
     */
    inputValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      checked: true,
      username: '',
      code: localStorage.getItem("invite_code") || "",
      lang: '',
      password: this.inputValue,
      passwordType: 'password',
      isDisabled: false
    };
  },
  mounted() {
    this.lang = localStorage.getItem("viodeLang") || this.$store.state.langDefault;
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },

    toKefu() {

      if (this.$store.getters.getBaseInfo.kefu) {

        window.open(this.$store.getters.getBaseInfo.kefu)
      }
    },
    switchPasswordType() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    back() {
      return window.history.back();
    },
    doRegister() {
      if (this.username === "" || this.username === null || this.username === undefined) {
        this.$toast.fail(this.$t("auth.username_place"));
        return false;
      }
      if (this.password === "" || this.password === null || this.password === undefined) {
        this.$toast.fail(this.$t("auth.pwd_place"));
        return false;
      }
      if (this.code === "" || this.code === null || this.code === undefined) {
        this.$toast.fail(this.$t("auth.invite_code_place"));
        return false;
      }
      if (!this.checked) {
        this.$toast.fail(this.$t("auth.agreement"));
        return false;
      }
      this.isDisabled = true
      this.$http({
        method: 'post',
        data: {
          username: this.username,
          password: this.password,
          code: this.code,
          lang: this.lang
        },
        url: 'member_register'
      }).then(res => {
        if (res.code === 200) {
          this.$toast.success(res.msg);
          localStorage.setItem("tokensso", res.data.tokensso);
          localStorage.setItem('token', res.data.id)
          this.$router.push("/au/home")
        } else {
          this.$toast.fail(res.msg);
        }
        this.isDisabled = false
      })
    }
  },
  created() {
    if (localStorage.getItem('token')) {
      return window.history.back();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";

.names {
  font-size: 28px;
  color: #fff;
  font-weight: 700;
}

.register {
  height: 100%;
}

.language {
  // position: absolute;
  // top: 4px;
  // right: 0;
  margin-left: 20px;
  height: 60px;

  img {
    height: 100%;
    border-radius: 50%;
  }
}

.bg-container .bg-wrapper .register .nav-bar {
  background: 0 0
}

.register .wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.logo-container {
  margin: 0 auto;
  width: 45%;
}

.logo-container .logo-wrapper {
  position: relative;
  padding-bottom: 62.5%;
}

.logo-container .logo-wrapper .logo-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  opacity: 0.8;
}

.register .wrapper .title {
  line-height: 100px;
  text-align: center;
  font-size: 45px;
  font-weight: 700;
  color: #fff;
  letter-spacing: 5px;
}

.register .wrapper .loginForm {
  top: 0;
  position: absolute;
  margin-top: 35vh;
  border-radius: 50px;
  opacity: .85;
  padding: 0 10vw;
  box-sizing: border-box;
  width: 100vw;
  padding: 60px;
}

.register .wrapper .loginForm .input {
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 35px;
  border-radius: 50px;
  text-align: center;
  line-height: 70px;
  font-size: 30px;
  color: #4e4e4e;
}

::v-deep .van-field__right-icon .van-icon {
  font-size: 50px;
}

::v-deep .van-icon {
  font-size: 50px;
}

.register .wrapper .loginForm .reset-text {
  margin: 30px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.register .wrapper .loginForm .reset-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 15px;
}

.register .wrapper .loginForm .register-text {
  margin: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register .wrapper .loginForm .register-text span {
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  line-height: 20px;
}

.register .wrapper .loginForm .login-btn {
  margin-top: 30px;
  width: 100%;
  height: 100px;
  border-radius: 50px;
  color: #fff;
  background: linear-gradient(90deg, #6529c9, #cc2996);
  font-size: 30px;
  font-weight: bolder;
  border: none;
}

.register .wrapper .loginForm .agreement {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.register .wrapper .loginForm .agreement .agreement-text {
  margin-left: 10px;
  font-size: 25px;
  color: #fff;
  font-weight: 500;
  line-height: 30px;
}

::v-deep .agreement .van-icon {
  font-size: 30px;
}

::v-deep .agreement .van-checkbox__icon {
  font-size: 38px;
}

::v-deep .agreement .van-checkbox__icon--checked .van-icon {
  color: #fff;
  border-color: rgb(126, 86, 120);
  background-color: rgb(126, 86, 120);
}


.logo {
  position: absolute;
  width: 90vw;
  top: 20vh;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 5vw;
}
</style>
